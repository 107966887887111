import React, { useState } from 'react';
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Alert, Button, CircleLoading, Form, makeStyles } from '@mediabank/uikit';
import PropTypes from 'prop-types';

import { Partner_Sign_In, Sign_In } from '../../messages';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        '& .MuiFormLabel-root': {
            color: 'white',
        },
        '& label, & input': {
            '&:-webkit-autofill': {
                '-webkit-box-shadow': `0 0 0 100px ${theme.palette.background.default} inset`,
            },
        },
    },
    submit: {
        width: '360px',
        height: theme.spacing(6),
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        background: theme.custom.accentMedium[1],
        '&:hover': {
            background: theme.custom.accentMedium[1],
        },
    },
    submitPartner: {
        width: '360px',
        height: theme.spacing(6),
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    logo: {
        maxWidth: '360px',
        objectFit: 'contain',
    },
    divider: {
        marginTop: theme.spacing(1),
    },
    alert: {
        border: 'none',
        padding: theme.spacing(0),
    },
    formControl: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
}));

const useInputStyles = makeStyles(() => ({
    root: {
        width: '360px',
    },
}));

const LoginForm = ({
    disableBtnPartner,
    disableBtnSubmit,
    error,
    isUserLoginLoading,
    onSubmit,
    partnerLoginUrl,
    password,
    setPassword,
    setShowError,
    setUserName,
    showError,
    userName,
    vendors,
}) => {
    const classes = useStyles();
    const inputClasses = useInputStyles();
    const [isPartnerSignIn, setIsPartnerSignIn] = useState(false);
    const [vendor, setVendor] = useState('');
    const [vendorUrl, setVendorUrl] = useState('');

    const handlePartnerSigninClick = () => {
        setIsPartnerSignIn(true);
    };

    const handleVendorChange = e => {
        setVendor(e.target.value);
        setVendorUrl(`${partnerLoginUrl}`.replace('{VENDOR}', e.target.value));
    };

    return (
        <Form className={classes.root} data-testid="loginForm-form" onSubmit={onSubmit}>
            <Grid alignContent="center" alignItems="stretch" container={true} direction="column">
                <Grid item={true} xs={true}>
                    <img alt="Logo" className={classes.logo} src="images/premiere_logo.png" />
                </Grid>
                <Grid item={true} xs={true}>
                    <TextField
                        data-testid="loginForm-userName-input"
                        error={showError}
                        InputProps={{ classes: inputClasses }}
                        label="Username"
                        margin="normal"
                        name="username"
                        value={userName}
                        variant="outlined"
                        onChange={event => {
                            setUserName(event.target.value);
                            setShowError(false);
                        }}
                    />
                </Grid>
                <Grid item={true} xs={true}>
                    <TextField
                        data-testid="loginForm-password-input"
                        error={showError}
                        InputProps={{ classes: inputClasses }}
                        label="Password"
                        margin="normal"
                        name="password"
                        type="password"
                        value={password}
                        variant="outlined"
                        onChange={event => {
                            setPassword(event.target.value);
                            setShowError(false);
                        }}
                    />
                </Grid>
                {showError && (
                    <Grid item={true} xs={true}>
                        <Alert className={classes.alert} severity="error">
                            {error}
                        </Alert>
                    </Grid>
                )}
                <Grid item={true} xs={true}>
                    <Button
                        className={classes.submit}
                        data-testid="loginForm-submit"
                        disabled={disableBtnSubmit}
                        type="submit"
                        variant="contained"
                    >
                        {isUserLoginLoading ? <CircleLoading height={32} size={24} /> : <Box>{Sign_In}</Box>}
                    </Button>
                </Grid>

                <Grid item={true} xs={true}>
                    <Divider className={classes.divider} />
                </Grid>
                {!isPartnerSignIn && (
                    <Grid item={true} xs={true}>
                        <Button
                            className={classes.submitPartner}
                            data-testid="loginForm-partner-sign-in"
                            disabled={disableBtnPartner}
                            type="button"
                            variant="contained"
                            onClick={handlePartnerSigninClick}
                        >
                            {Partner_Sign_In}
                        </Button>
                    </Grid>
                )}

                {isPartnerSignIn && (
                    <>
                        <Grid item={true} xs={true}>
                            <FormControl className={classes.formControl} variant="outlined">
                                <InputLabel id="vendor-label">Select your partner</InputLabel>
                                <Select
                                    data-testid="loginForm-partner-select"
                                    id="vendor-select"
                                    label="Select your partner"
                                    labelId="vendor-label"
                                    value={vendor}
                                    onChange={handleVendorChange}
                                >
                                    {vendors.map(item => (
                                        <MenuItem
                                            key={item.id}
                                            data-testid={`loginForm-option-${item.id}`}
                                            value={item.id}
                                        >
                                            {item?.header_key?.toUpperCase()}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={true}>
                            <Button
                                className={classes.submitPartner}
                                data-testid="loginForm-partner-continue"
                                disabled={!vendor}
                                href={vendorUrl}
                                type="button"
                                variant="contained"
                            >
                                CONTINUE
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </Form>
    );
};

LoginForm.propTypes = {
    disableBtnPartner: PropTypes.bool.isRequired,
    disableBtnSubmit: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    isUserLoginLoading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    partnerLoginUrl: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    setPassword: PropTypes.func.isRequired,
    setUserName: PropTypes.func.isRequired,
    setShowError: PropTypes.func.isRequired,
    showError: PropTypes.bool.isRequired,
    userName: PropTypes.string.isRequired,
    vendors: PropTypes.array.isRequired,
};

export { LoginForm };
