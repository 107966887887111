import React from 'react';
import { Box, Button } from '@material-ui/core';
import cx from 'clsx';
import PropTypes from 'prop-types';

import { usePanelTabStyles } from './styles';

const PanelTab = ({ label, onClick, selected, variant, breadcrumbs, className, buttonLabelClass }) => {
    const classes = usePanelTabStyles();
    const buttonClassName = cx(classes.generalBtnStyle, classes[variant], className, {
        [classes[`${variant}Selected`]]: selected,
    });

    return (
        <>
            <Button
                classes={{ label: buttonLabelClass }}
                className={buttonClassName}
                data-cy={`paneltabButton${selected ? 'Selected' : ''}`}
                variant="text"
                onClick={onClick}
            >
                {label}
                {variant === 'level1' && <div className={classes.bottomLine} />}
            </Button>
            {breadcrumbs && (
                <Box className={classes.breadcrumbsContainer} data-cy="breadcrumbs">
                    {breadcrumbs}
                </Box>
            )}
        </>
    );
};

PanelTab.propTypes = {
    breadcrumbs: PropTypes.element,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    variant: PropTypes.oneOf(['level1', 'level2']),
    className: PropTypes.string,
    buttonLabelClass: PropTypes.string,
};

PanelTab.defaultProps = {
    breadcrumbs: null,
    onClick: undefined,
    selected: false,
    variant: 'level1',
    className: '',
    buttonLabelClass: '',
};

export default PanelTab;
